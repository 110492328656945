import React, { useEffect } from "react"
import { Input, Form, Modal, Switch } from "antd"

const { TextArea } = Input

function FileModal({ visible, onCreate, onCancel, data }) {
  const [form] = Form.useForm()

  let initValues = {
    name: data?.name,
    url: data?.url,
    note: data?.note,
    public: data?.public,
  }

  useEffect(() => {
    return form.setFieldsValue(initValues)
  }, [form, initValues])

  return (
    <Modal
      forceRender
      visible={visible}
      title="Data"
      okText="Submit"
      cancelText="Cancel"
      onCancel={() => {
        form.resetFields()
        onCancel()
      }}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            onCreate(values)
            form.resetFields()
          })
          .catch(info => {
            //console.log("Validate Failed:", info)
          })
      }}
      destroyOnClose
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item label="Public" name="public" valuePropName="checked">
          <Switch defaultChecked checkedChildren="On" unCheckedChildren="Off" />
        </Form.Item>

        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please enter name",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="url"
          label="URL"
          rules={[
            {
              required: true,
              message: "Please enter url",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="note" label="Note">
          <TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default FileModal
