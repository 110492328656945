import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import { Table, Card, Button, Popconfirm } from "antd"
import firebase from "gatsby-plugin-firebase"
import { FileService } from "../services/file.service"
import FileUploader from "../components/fileUploader"
import FileModal from "../components/fileModal"
import PageHeading from "../components/pageHeading"

export default function FileBrowser({ location }) {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "30%",
    },
    {
      title: "type",
      dataIndex: "type",
      width: "46%",
    },
    {
      title: "size",
      dataIndex: "size",
    },
  ]

  const dbPath = location?.state?.path

  const [fileData, setFileData] = useState([])
  const [fileModal, setFileModal] = useState(false)
  const [uploadModal, setUploadModal] = useState(false)
  const [onEdit, setOnEdit] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([]) //countrolled the selectedRowKeys(antd reserved keyword!)
  const [selectedRow, setSelectedRow] = useState({})

  const rowSelection = {
    onChange: selectedRowKeys => {
      setSelectedRowKeys(selectedRowKeys) //update selected key according to radio selection
    },
  }

  useEffect(() => {
    return firebase
      .firestore()
      .collection(dbPath)
      .onSnapshot(snapshot => {
        const data = []
        snapshot.forEach(doc => data.push({ ...doc.data(), key: doc.id }))
        setFileData(data)
      })
  }, [])

  // create or edit file conditionally
  const onCreate = values => {
    Object.keys(values).forEach(
      key => values[key] === undefined && delete values[key]
    )
    if (onEdit) {
      FileService.updateFile(values, dbPath + selectedRow.key)
    } else {
      values["type"] = "link"
      FileService.createFile(values, dbPath)
    }

    setFileModal(false)
  }

  //delete data condition on file type
  const handleDelete = () => {
    if (selectedRow.type === "link") {
      FileService.deleteLink(dbPath + selectedRow.key)
    } else {
      FileService.deleteFile(dbPath + selectedRow.key, selectedRow.path)
    }

    setSelectedRow({})
  }

  //prepare data for model conditional on edit vs add link
  const modalData = () => {
    if (onEdit) {
      return selectedRow
    }
    return { public: true }
  }

  // console.log("fileData", fileData[0].url)

  return (
    <Layout>
      <PageHeading title="File Browser" className="w-full" />
      <div className="w-full flex flex-row justify-between gap-5 py-8">
        <div className="">
          <Table
            style={{ width: 540 }}
            className="m-0 p-0"
            rowSelection={{
              type: "radio",
              selectedRowKeys,
              ...rowSelection,
            }}
            onRow={r => ({
              onClick: () => {
                setSelectedRow(r)
                setSelectedRowKeys([r.key]) //update selected key according to row select, be mindful of data format
              },
            })}
            columns={columns}
            dataSource={fileData}
            pagination={{ position: ["bottomCenter"] }}
            title={() => (
              <div className="flex flex-row justify-between">
                <div className="text-lg">File Browser</div>
                <div className="flex flex-row-reverse">
                  <Button
                    onClick={() => {
                      setOnEdit(false)
                      setFileModal(true)
                    }}
                  >
                    Add Link
                  </Button>
                  <Button
                    onClick={() => {
                      setUploadModal(true)
                    }}
                  >
                    Add File
                  </Button>
                </div>
              </div>
            )}
          />
        </div>

        <div>
          <Card
            style={{ width: 480 }}
            title=""
            extra={
              <div className="flex flex-row-reverse">
                <Popconfirm
                  disabled={Object.entries(selectedRow).length === 0}
                  title="Sure to delete?"
                  onConfirm={() => handleDelete()}
                >
                  <Button disabled={Object.entries(selectedRow).length === 0}>
                    Delete
                  </Button>
                </Popconfirm>
                <Button disabled={Object.entries(selectedRow).length === 0}>
                  Download
                </Button>
                <Button
                  disabled={Object.entries(selectedRow).length === 0}
                  onClick={() => {
                    setOnEdit(true)
                    setFileModal(true)
                  }}
                >
                  Edit
                </Button>
              </div>
            }
          >
            {Object.entries(selectedRow).length === 0 ? null : (
              <div className="w-96">
                <p>
                  Name:
                  <br />
                  {selectedRow.name}
                </p>
                {selectedRow.type === "link" ? (
                  <p>
                    URL: <br />
                    {selectedRow.url}
                  </p>
                ) : (
                  <></>
                )}
                {selectedRow.type === "image/jpeg" ? (
                  <img className="max-w-96" src={selectedRow.url} alt="" />
                ) : (
                  <></>
                )}
                {selectedRow.type === "application/pdf" ? (
                  <iframe
                    src={
                      selectedRow.url + `#scrollbars=1&toolbar=1&statusbar=1`
                    }
                    name="pdfpreview"
                    title={selectedRow.name}
                    width="440"
                    height="500"
                    frameBorder="1"
                  ></iframe>
                ) : null}

                {selectedRow.note ? (
                  <p>
                    Note: <br />
                    {selectedRow.note}
                  </p>
                ) : null}
              </div>
            )}
          </Card>
        </div>
      </div>

      {/* this is for "add file" modal */}
      <FileUploader
        visible={uploadModal}
        onCancel={() => {
          setUploadModal(false)
        }}
        path={dbPath}
        multiple={true}
      />

      {/* this is for "add link" modal */}
      <FileModal
        visible={fileModal}
        onCancel={() => {
          setFileModal(false)
        }}
        onCreate={onCreate}
        data={modalData()}
      />
    </Layout>
  )
}
